import { useOktaAuth } from "@okta/okta-react";
import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const { authState } = useOktaAuth();

  if (authState && !authState.isAuthenticated) {
    sessionStorage.setItem("path", window.location.pathname);
    return <Navigate replace={true} to="/" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default PrivateRoute;
