import * as TYPE from "../../constants";

const initialState = {
  loading: false,
  cart: {},
  error: "",
  orderPlacedData: {},
};

export const cartReducer = (state = initialState, action) => {
  console.log("action.payload", action.payload);
  switch (action.type) {
    case TYPE.GET_CART_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPE.GET_CART_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        cart: action.payload,
      };
    case TYPE.CLEAR_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        cart: action.payload,
      };
    case TYPE.GET_CART_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case TYPE.CART_DETAILS_UPDATE:
      return {
        ...state,
        loading: false,
        cart: action.payload,
      };
    case TYPE.ORDER_PLACE:
      return {
        ...state,
        loading: false,
        orderPlacedData: action.payload,
      };
    default:
      return state;
  }
};
