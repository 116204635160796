import * as Type from "../../constants";
import ApiClient from "../../apiClient/api";

import { get_header_with_xauth } from "../../FetchIntercepter";
import Url from "../../configs/UrlContants";
import {
  manageUser,
  userDetails,
  users,
  handshaking,
} from "../../configs/ApiRoutes";
const { apiUrlWeb } = Url;

export const loginStart = () => ({
  type: Type.LOGIN_START,
});

export const loginSuccess = (user) => ({
  type: Type.LOGIN_SUCCESS,
  payload: user,
});

export const loginFailure = (error) => ({
  type: Type.LOGIN_FAILURE,
  payload: error,
});

export const logoutUser = () => ({
  type: Type.LOGOUT_USER,
});

export const getAllUsers = () => {
  return (dispatch, getState) => {
    dispatch({ type: Type.GET_USERS_REQUEST });
    ApiClient.getWithoutParams(
      `${apiUrlWeb}${users}
    `,
      get_header_with_xauth()
    ).then((response) => {
      if (response.success) {
        dispatch({ type: Type.GET_USERS_SUCCESS, payload: response.data });
      } else {
        // dispatch(is_fetching(false));
      }
    });
  };
};

export const userLogin = (accountId) => {
  const params = {
    // account_id: accountId,
  };
  let response;
  return async (dispatch, getState) => {
    dispatch(loginStart());
    try {
      response = await ApiClient.postWithParams(
        `${apiUrlWeb}${userDetails}`,
        params,
        get_header_with_xauth()
      );
      if (response) {
        dispatch(loginSuccess(response.data));
      }
    } catch (err) {
      dispatch(loginFailure("Something went wrong!"));
    }

    return Promise.resolve(response);
  };
};

export const manageUserAccount = (params) => async (dispatch) => {
  const response = await ApiClient.postWithParams(
    `${apiUrlWeb}${manageUser}`,
    params,
    get_header_with_xauth()
  );

  return Promise.resolve(response);
};

export const oktaHandshaking = (sessionId) => async (dispatch) => {
  const params = {
    sessionId,
  };
  const res = await ApiClient.postWithParams(
    `${apiUrlWeb}${handshaking}
    `,
    params,
    get_header_with_xauth()
  );
  console.log(res);
};
