import { persistCombineReducers } from "redux-persist";
import storage from "redux-persist/es/storage";
import { connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import userReducer from "./modules/userReducer";
import { productListReducer } from "./modules/productsReducer";
import { newsReducer } from "./modules/newsReducer";
import { productReducer } from "./modules/productReducer";
import { userListReducer } from "./modules/usersReducers";
import { shoppingCartReducer } from "./modules/shoppingCartReducer";
import { orderReducer } from "./modules/orderReducer";
import { programsListReducer } from "./modules/programsReducer";
import { divlocsListReducer } from "./modules/divlocsReducer";
import { shippingAddListReducer } from "./modules/shippingReducer";
import { cartReducer } from "./modules/cartReducer";

const history = createBrowserHistory();

const userPersistConfig = {
  key: "ePOP",
  storage: storage,
  // transforms: [encryptor],
  //   whiteList: ["user"],
};

export default persistCombineReducers(userPersistConfig, {
  router: connectRouter(history),
  user: userReducer,
  users: userListReducer,
  productList: productListReducer,
  news: newsReducer,
  selectcard: productReducer,
  shoppingCart: shoppingCartReducer,
  orderCart: orderReducer,
  programsList: programsListReducer,
  divlocList: divlocsListReducer,
  shipAddresses: shippingAddListReducer,
  cartReducer: cartReducer,
});
