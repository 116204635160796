import * as TYPE from "../../constants";

const initialState = {
  loading: false,
  products: [],
  filterList: [],
  colorFilterList: [],
  programFilterList: [],
  filterListMock: [],
  productListMock: [],
  error: "",
};

export const productListReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPE.GET_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPE.SET_PRODUCT_LIST_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case TYPE.GET_PRODUCTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
