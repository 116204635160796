const INIT_STATE = {
  selectcard: [],
  cart: [],
  // shoppingCart: [],
};

export const productReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "SELECT_CARD":
      return {
        ...state,
        selectcard: action.payload,
      };

    // case "ADD_CART":
    //   //console.log("action.payload", action.payload);
    //   //   const exist = cardInfo?.ProductInfo?.ProductDetails?.find(
    //   //     (x) => x.sku === product.sku
    //   //   );
    //   //   console.log("exist", exist);
    //   //   if (exist) {
    //   //     setCartItems(
    //   //       cardInfo?.ProductInfo?.ProductDetails?.map((x) =>
    //   //         x.sku === product.sku ? { ...exist, qty: exist.qty + 1 } : x
    //   //       )
    //   //     );
    //   //   } else {
    //   //     setCartItems([...cardInfo, { ...product, qty: 1 }]);
    //   //   }
    //   //   console.log("cartItmes", cartItems);
    //   return {
    //     ...state,
    //     shoppingCart: action.payload,
    //   };

    default:
      return state;
  }
};
