import React, { Suspense } from "react";
// import CssBaseline from "@mui/material/CssBaseline";
// import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import ErrorBoundry from "./components/commonComponents/ErrorBoundary";
import { useRoutes } from "react-router-dom";
import { publicRoutes } from "./routes/routes";
// Slick Slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-datepicker/dist/react-datepicker.css";
import "./assets/sass/style.scss";
import { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { clearCacheData } from "./util/Utils";

function App() {
  const routing = useRoutes(publicRoutes);
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    if (
      authState &&
      !authState.isAuthenticated &&
      window.location.pathname !== "/"
    ) {
      clearCacheData();
      oktaAuth.signOut("/");
    }
  });

  // on okta session expire
  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      const tokenManager = oktaAuth.tokenManager;

      const tokenExpHandler = () => {
        clearCacheData();
        oktaAuth.signOut("/");
      };

      tokenManager.on("expired", tokenExpHandler);

      return () => {
        tokenManager.off("expired", tokenExpHandler); // Clean up the event handler
      };
    }
  }, [authState, oktaAuth]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <ErrorBoundry>
      <Suspense
        fallback={
          <p style={{ left: "35%", padding: 200, position: "relative" }}></p>
        }
      >
        {routing}
      </Suspense>
    </ErrorBoundry>
  );
}

export default App;
