/*
 * @file: constants.js
 * @description: It Contain action types Related Action constants.
 * @author: MCOE
 */

/************ User *************/
export const REGISTER_USERS = "REGISTER_USERS";
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_USER = "LOGOUT_USER";

/************ Get All Users *************/
export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";

/************ Get Product Details *************/
export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const SET_PRODUCT_LIST_DATA = "SET_PRODUCT_LIST_DATA";
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL";

/************ Get All News *************/
export const GET_NEWS_REQUEST = "GET_NEWS_REQUEST";
export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS";
export const SET_NEWS_DATA = "SET_NEWS_DATA";
export const GET_NEWS_FAIL = "GET_NEWS_FAIL";

/************ Get All Programs *************/
export const GET_PROGRAMS_REQUEST = "GET_PROGRAMS_REQUEST";
export const GET_PROGRAMS_SUCCESS = "GET_PROGRAMS_SUCCESS";
export const GET_PROGRAMS_FAIL = "GET_PROGRAMS_FAIL";

/************ Get All Divlocs *************/
export const GET_DIVLOCS_REQUEST = "GET_DIVLOCS_REQUEST";
export const GET_DIVLOCS_SUCCESS = "GET_DIVLOCS_SUCCESS";
export const GET_DIVLOCS_FAIL = "GET_DIVLOCS_FAIL";

/************ Get All Shipping Addresses *************/
export const GET_SHIPPING_ADD_REQUEST = "GET_SHIPPING_ADD_REQUEST";
export const SET_SHIPPING_DATA = "SET_SHIPPING_DATA";
export const GET_SHIPPING_ADD_SUCCESS = "GET_SHIPPING_ADD_SUCCESS";
export const GET_SHIPPING_ADD_FAIL = "GET_SHIPPING_ADD_FAIL";
/************ User *************/
export const GET_CART_DETAILS_REQUEST = "GET_CART_DETAILS_REQUEST";
export const GET_CART_DETAILS_SUCCESS = "GET_CART_DETAILS_SUCCESS";
export const GET_CART_DETAILS_FAIL = "GET_CART_DETAILS_FAIL";
export const CART_DETAILS_UPDATE = "CART_DETAILS_UPDATE";

export const CLEAR_CART_REQUEST = "CLEAR_CART_REQUEST";
export const CLEAR_CART_SUCCESS = "CLEAR_CART_SUCCESS";
export const CLEAR_CART_FAIL = "CLEAR_CART_FAIL";

export const DELETE_CART_REQUEST = "DELETE_CART_REQUEST";
export const DELETE_CART_SUCCESS = "DELETE_CART_SUCCESS";
export const DELETE_CART_FAIL = "DELETE_CART_FAIL";

/************ View Order *************/
export const VIEW_ORDER_FILTER_STATE = "VIEW_ORDER_FILTER_STATE";
export const ORDER_HISTORY_LIST = "ORDER_HISTORY_LIST";
export const ORDER_PLACE = "ORDER_PLACE";
