import * as TYPE from "../../constants";

/******** Reducers ********/
const initialState = {
  userDetails: {},
  loggedIn: false,
  loading: false,
  error: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TYPE.LOGIN_START:
      return {
        ...state,
        loading: true,
      };
    case TYPE.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedIn: true,
        error: "",
        userDetails: action.payload,
      };
    case TYPE.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        loggedIn: false,
        error: action.payload,
      };
    case TYPE.LOGOUT_USER:
      return {
        ...state,
        loggedIn: false,
        error: "",
        userDetails: {},
      };
    default:
      return state;
  }
}
