const INIT_STATE = {
  selectcard: [],
  cart: [],
  shoppingCart: [],
};

export const shoppingCartReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    //   case "SELECT_CARD":
    //     return {
    //       ...state,
    //       selectcard: action.payload,
    //     };

    case "ADD_CART":
      //console.log("action.payload", action.payload);
      //   const exist = cardInfo?.ProductInfo?.ProductDetails?.find(
      //     (x) => x.sku === product.sku
      //   );
      //   console.log("exist", exist);
      //   if (exist) {
      //     setCartItems(
      //       cardInfo?.ProductInfo?.ProductDetails?.map((x) =>
      //         x.sku === product.sku ? { ...exist, qty: exist.qty + 1 } : x
      //       )
      //     );
      //   } else {
      //     setCartItems([...cardInfo, { ...product, qty: 1 }]);
      //   }
      //   console.log("cartItmes", cartItems);
      let totalItems = [];
      // let newItems = [];
      for (let ci of state.shoppingCart) {
        for (let pi of action.payload) {
          console.log("shoppingCart", ci);
          console.log("action.payload", pi);
          if (ci.Eqi === pi.Eqi && ci.sku === pi.sku) {
            ci.qty = pi.qty;
            //newItems = [...state.shoppingCart, ci];
            // newItems.push(ci);
          } // } else {
          //   newItems.push(pi);
          // }
        }
      }

      totalItems = [...state.shoppingCart, ...action.payload];

      return {
        ...state,
        shoppingCart:
          state.shoppingCart.length === 0 ? action.payload : totalItems,
      };

    case "DELETE_ALL":
      // console.log(data);

      return {
        ...state,
        shoppingCart: [],
      };

    case "DELETE_ONE":
      // const IteamIndex_dec = state.carts.findIndex(
      //   (iteam) => iteam.id === action.payload.id
      // );

      // if (state.carts[IteamIndex_dec].qnty >= 1) {
      //   const dltiteams = (state.carts[IteamIndex_dec].qnty -= 1);
      //   console.log([...state.carts, dltiteams]);

      //   return {
      //     ...state,
      //     carts: [...state.carts],
      //   };
      // } else if (state.carts[IteamIndex_dec].qnty === 1) {

      const data1 = state.shoppingCart.filter(
        (el) => el.sku !== action.payload.sku
      );

      return {
        ...state,
        shoppingCart: data1,
      };

    default:
      return state;
  }
};
