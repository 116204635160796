import React from "react";
import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div className="main-wrapper">
          {/* Page Not Found Content */}
          <div className="contact-us pagenotfound mb-40">
            <Container>
              <div className="inner-wrapper">
                <Container>
                  <div className="empty-cart my-40 text-center">
                    <img
                      loading="lazy"
                      src="https://share.snacks.com/images/mcoe/serverErr.png"
                      width={600}
                      alt="Page Not Found"
                    />
                    <div className="empty-content">
                      <h6 className="empty-cart-heading">
                        Whoops, something Went Wrong.
                      </h6>
                      {/* <details
                        style={{ whiteSpace: "pre-wrap" }}
                        className="text-left"
                      >
                        {this.state.error && this.state.error.toString()}
                        <br />
                        {this.state.errorInfo?.componentStack}
                      </details> */}
                      <p className="mb-0">
                        Please refresh the page to try again.
                      </p>
                      <Button
                        onClick={() => window.location.reload()}
                        className="btn-primary btn"
                      >
                        Refresh
                      </Button>
                    </div>
                  </div>
                </Container>
              </div>
            </Container>
          </div>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
