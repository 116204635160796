import React from "react";
import { Outlet } from "react-router-dom";
// import { LoginCallback } from "@okta/okta-react";
import PrivateRoute from "./PrivateRoutes";
import LoginCallback from "util/LoginCallback";
const MainLayout = React.lazy(() =>
  import("../components/layout/main-layout/MainLayout")
);
const Login = React.lazy(() => import("../pages/login/Login"));
const ForgotPassword = React.lazy(() =>
  import("../pages/forget-password/ForgotPassword")
);
const Dashboard = React.lazy(() => import("../pages/dashboard/Dashboard"));
const ContactUs = React.lazy(() => import("../pages/contactus/ContactUs"));
const PageNotFound = React.lazy(() =>
  import("../pages/PageNotFound/PageNotFound")
);
const Help = React.lazy(() => import("../pages/help/Help"));
const ManageUser = React.lazy(() => import("../pages/manage-user/ManageUser"));
const ManagePrograms = React.lazy(() =>
  import("../pages/manage-programs/ManagePrograms")
);
const ProductList = React.lazy(() => import("../pages/products/ProductList"));
const ShoppingCart = React.lazy(() =>
  import("../pages/shopping-cart/ShoppingCart")
);
const ReviewOrder = React.lazy(() =>
  import("../pages/review-order/ReviewOrder")
);
const UpdateShipto = React.lazy(() =>
  import("../pages/update-shipto/UpdateShipto")
);
const ClearCart = React.lazy(() => import("../pages/ClearCart"));
const OrderConfirmation = React.lazy(() =>
  import("../pages/OrderConfirmation")
);
const News = React.lazy(() => import("../pages/news/News"));
const NewsDetails = React.lazy(() => import("../pages/news/NewsDetails"));
const ManageNews = React.lazy(() => import("../pages/news/ManageNews"));
const MyAccount = React.lazy(() => import("../pages/MyAccount/MyAccount"));
const ViewOrder = React.lazy(() => import("../pages/view-order/ViewOrder"));
const ViewOrderDetails = React.lazy(() =>
  import("../pages/view-order/ViewOrderDetails")
);
const ManageDivloc = React.lazy(() =>
  import("../pages/manage-divloc/ManageDivloc")
);
const DivlocBulkupload = React.lazy(() =>
  import("../pages/manage-divloc/DivlocBulkupload")
);
const BulkOrderCreation = React.lazy(() =>
  import("../pages/BulkOrderCreation/BulkOrderCreation")
);

const ViewBulkOrderHistory = React.lazy(() =>
  import("../pages/BulkOrderCreation/ViewBulkOrderHistory")
);
const BulkProductCreation = React.lazy(() =>
  import("../pages/BulkProductCreation/BulkProductCreation")
);
const Inventory = React.lazy(() => import("../pages/Inventory/Inventory"));
const InventoryList = React.lazy(() =>
  import("../pages/Inventory/InventoryList")
);
const BulkUpload = React.lazy(() => import("../pages/BulkUpload/BulkUpload"));
const Report = React.lazy(() => import("../pages/Report/Report"));

export const publicRoutes = [
  {
    path: "",
    element: <MainLayout />,
    exact: true,
    children: [
      {
        path: "/",
        element: <Login />,
        exact: true,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
        exact: true,
      },
      {
        path: "dashboard",
        element: (
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        ),
        exact: true,
      },
      {
        path: "contact-us",
        element: (
          <PrivateRoute>
            <ContactUs />
          </PrivateRoute>
        ),
        exact: true,
      },
      {
        path: "help",
        element: (
          <PrivateRoute>
            <Help />
          </PrivateRoute>
        ),
        exact: true,
      },
      {
        path: "manage-user",
        element: (
          <PrivateRoute>
            <ManageUser />
          </PrivateRoute>
        ),
        exact: true,
      },
      {
        path: "manage-programs",
        element: (
          <PrivateRoute>
            <ManagePrograms />
          </PrivateRoute>
        ),
        exact: true,
      },
      {
        path: "product-list",
        element: <Outlet />,
        exact: true,
        children: [
          {
            path: "/product-list",
            element: (
              <PrivateRoute>
                <ProductList />
              </PrivateRoute>
            ),
            exact: true,
          },
          {
            path: "shopping-cart",
            element: <Outlet />,
            exact: true,
            children: [
              {
                path: "/product-list/shopping-cart",
                element: (
                  <PrivateRoute>
                    <ShoppingCart />
                  </PrivateRoute>
                ),
                exact: true,
              },
              {
                path: "review-order",
                element: (
                  <PrivateRoute>
                    <ReviewOrder />
                  </PrivateRoute>
                ),
                exact: true,
              },
            ],
          },
        ],
      },
      {
        path: "manage-shipping",
        element: (
          <PrivateRoute>
            <UpdateShipto />
          </PrivateRoute>
        ),
        exact: true,
      },
      {
        path: "empty-cart",
        element: (
          <PrivateRoute>
            <ClearCart />
          </PrivateRoute>
        ),
        exact: true,
      },
      {
        path: "order-confirmation/:id",
        element: (
          <PrivateRoute>
            <OrderConfirmation />
          </PrivateRoute>
        ),
        exact: true,
      },
      {
        path: "news",
        element: <Outlet />,
        exact: true,
        children: [
          {
            path: "/news",
            element: (
              <PrivateRoute>
                <News />
              </PrivateRoute>
            ),
            exact: true,
          },
          {
            path: "news-details/:id",
            element: (
              <PrivateRoute>
                <NewsDetails />
              </PrivateRoute>
            ),
            exact: true,
          },
        ],
      },
      {
        path: "manage-news",
        element: (
          <PrivateRoute>
            <ManageNews />
          </PrivateRoute>
        ),
        exact: true,
      },
      {
        path: "my-account",
        element: (
          <PrivateRoute>
            <MyAccount />
          </PrivateRoute>
        ),
        exact: true,
      },
      {
        path: "view-order",
        element: <Outlet />,
        exact: true,
        children: [
          {
            path: "/view-order",
            element: (
              <PrivateRoute>
                <ViewOrder />
              </PrivateRoute>
            ),
            exact: true,
          },
          {
            path: "view-order-details/:id",
            element: (
              <PrivateRoute>
                <ViewOrderDetails />
              </PrivateRoute>
            ),
            exact: true,
          },
        ],
      },
      {
        path: "manage-divloc",
        element: (
          <PrivateRoute>
            <ManageDivloc />
          </PrivateRoute>
        ),
        exact: true,
      },
      {
        path: "divloc-bulkupload",
        element: (
          <PrivateRoute>
            <DivlocBulkupload />
          </PrivateRoute>
        ),
        exact: true,
      },
      {
        path: "/implicit/callback",
        element: (
          <PrivateRoute>
            <LoginCallback />
          </PrivateRoute>
        ),
        exact: true,
      },
      // {
      //   path: "bulk-order-creation",
      //   element: (
      //     <PrivateRoute>
      //       <BulkOrderCreation />
      //     </PrivateRoute>
      //   ),
      //   exact: true,
      // },
      // {
      //   path: "bulk-order-history",
      //   element: (
      //     <PrivateRoute>
      //       <ViewBulkOrderHistory />
      //     </PrivateRoute>
      //   ),
      //   exact: true,
      // },
      {
        path: "bulk-order-creation",
        element: <Outlet />,
        exact: true,
        children: [
          {
            path: "/bulk-order-creation",
            element: (
              <PrivateRoute>
                <BulkOrderCreation />
              </PrivateRoute>
            ),
            exact: true,
          },
          {
            path: "bulk-order-history",
            element: (
              <PrivateRoute>
                <ViewBulkOrderHistory />
              </PrivateRoute>
            ),
            exact: true,
          },
        ],
      },
      {
        path: "bulk-product",
        element: (
          <PrivateRoute>
            <BulkProductCreation />
          </PrivateRoute>
        ),
        exact: true,
      },
      {
        path: "inventory",
        element: (
          <PrivateRoute>
            <Inventory />
          </PrivateRoute>
        ),
        exact: true,
      },
      {
        path: "inventory-list",
        element: (
          <PrivateRoute>
            <InventoryList />
          </PrivateRoute>
        ),
        exact: true,
      },
      {
        path: "bulk-upload",
        element: (
          <PrivateRoute>
            <BulkUpload />
          </PrivateRoute>
        ),
        exact: true,
      },
      {
        path: "reports",
        element: (
          <PrivateRoute>
            <Report />
          </PrivateRoute>
        ),
        exact: true,
      },
      {
        path: "*",
        element: <PageNotFound />,
        exact: true,
      },
    ],
  },
];

// export const privateRoutes = [
//   {
//     path: "/dashboard",
//     Component: React.lazy(
//       () =>
//         new Promise((resolve, reject) => {
//           setTimeout(() => {
//             resolve(
//               import(
//                 /*webpackChunkName:'Dashboard-Chunk' */ "../pages/help/Help"
//               )
//             );
//           }, 100);
//         })
//     ),
//     exact: true,
//   },
// ];
