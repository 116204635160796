import qs from "query-string";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Fragment, useState } from "react";
import { Alert } from "react-bootstrap";
import { clearCacheData } from "./Utils";
import { useOktaAuth } from "@okta/okta-react";
import OktaApiUtil from "configs/OktaApiUtil";
import { userLogin } from "actions/user";
import Loader from "components/commonComponents/Loader";

const LoginCallback = (props) => {
  const navigate = useNavigate();
  const [showWarning, setShowWarning] = useState(false);
  const [showWarningMsg, setShowWarningMsg] = useState();
  const [loading, setLoading] = useState(true);
  const { oktaAuth } = useOktaAuth();
  const dispatch = useDispatch();

  let params = qs.parse(window.location.search);
  let code = params ? params.code : "";
  let err = params ? params.error : "";

  if (code) {
    setLoading(true);
    OktaApiUtil.getToken(code).then(async (res) => {
      const userRoles = res.tokens.accessToken.claims.pepappmcoeroles;
      const response = await dispatch(userLogin());

      if (userRoles.length && response?.success) {
        sessionStorage.removeItem("isSiteAccessErr");
        sessionStorage.removeItem("isUserSetupErr");
        navigate("/dashboard", { state: { from: "login" } });
        setLoading(false);
      } else {
        if (!userRoles.length) {
          sessionStorage.setItem("isSiteAccessErr", true);
        } else if (!response?.success) {
          sessionStorage.setItem("isUserSetupErr", true);
        }
        clearCacheData();
        oktaAuth.signOut("/");
      }
    });
  }
  if (err) {
    setLoading(false);
    navigate("/");
  }
  return (
    <Fragment>
      <div className="main-wrapper">
        {loading && <Loader />}
        {/*======== WARNING ALERT MESSAGE ========*/}
        <Alert
          variant="warning"
          className="alert-popup"
          onClose={() => setShowWarning(false)}
          show={showWarning}
          dismissible
        >
          <Alert.Heading>Warning</Alert.Heading>
          <p className="mb-0">{showWarningMsg}</p>
        </Alert>
      </div>
    </Fragment>
  );
};

// const handleOnBehalfOfLogic = () => {
//   let handoffParams = sessionStorage.getItem("handoff_params");
//   let parsedQueryParam = qs.parse(handoffParams);
//   if (
//     !parsedQueryParam.accountId ||
//     !parsedQueryParam.accountId ||
//     !parsedQueryParam.page
//   ) {
//     return false;
//   }
//   const reqPayload = {
//     accountId: parsedQueryParam.accountId,
//     contactId: parsedQueryParam.contactId,
//     sfToken: parsedQueryParam.sfToken || "random",
//   };
//   sessionStorage.removeItem("handoff_params");
//   // let agentId = parsedQueryParam.agentId ? parsedQueryParam.agentId : "";
//   let targetPath = parsedQueryParam.page
//     ? parsedQueryParam.pageParameterName && parsedQueryParam.pageParameterValue
//       ? parsedQueryParam.page.toLowerCase() +
//         "?" +
//         parsedQueryParam.pageParameterName +
//         "=" +
//         parsedQueryParam.pageParameterValue
//       : parsedQueryParam.page.toLowerCase()
//     : "/productlist";
//   APIUtil.postMethodWithAuth(UrlConstants.validateaccountid, reqPayload, true)
//     .then((response) => {
//       sessionStorage.setItem("validateapi_response", JSON.stringify(response));
//       if (response.data.data && response.data.data.refreshToken) {
//         OktaApiUtil.refreshToken().then(() => {
//           window.location.href = targetPath;
//         });
//       } else {
//         window.location.href = targetPath;
//       }
//     })
//     .catch((error) => {
//       console.log(error);
//       return error.response;
//     });
// };

export default LoginCallback;
