import React from "react";
import { createBrowserHistory } from "history";
import ReactDOM from "react-dom/client";
import App from "./App.js";
import reportWebVitals from "./reportWebVitals";
import { Navigate, BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import configureStore from "./store";
import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import config from "./util/config";
import { clearCacheData } from "./util/Utils.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
const history = createBrowserHistory();
const oktaAuth = new OktaAuth(config.oidc);

// on okta session expire
// oktaAuth.tokenManager.on("expired", function (key, expiredToken) {
//   clearCacheData();
//   oktaAuth.signOut("/");
// });

const { persistor, store } = configureStore(history);

const restoreOriginalUri = async (_oktaAuth, originalUri) => {
  history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
};

root.render(
  <Router>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <App />
        </Security>
      </PersistGate>
    </Provider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
