export const rnduts =
  "rWt9nnVmfkjHW4eZsZmC2Yufspi2qHqNqs2W6rN5paiYnrSXwa2dqt1grI+Kn5zql3Whh5p5o6aJrKqn";

export const encstr =
  "ZJ14fYu2mrCIkYdtin+abJxpjnq7nItnfHvFtKh9g6GopmfHZbiWm93YlaZzba+fpm5mk5xlZ2ycbm1pkXCosQ==";

export const X_Auth_Token =
  sessionStorage.getItem("accessToken") !== null
    ? JSON.parse(sessionStorage.getItem("accessToken")).accessToken
    : "";

export const sessionId = "_ss_1662121702937id7044310be80b";

export const header_without_xauth = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  sessionId: sessionId,
  rnduts: rnduts,
  encstr: encstr,
};

export const header_with_xauth = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  sessionId: sessionId,
  rnduts: rnduts,
  encstr: encstr,
  "X-Auth-Token": X_Auth_Token,
};

export const get_header_with_xauth = () => {
  return {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    sessionId: sessionStorage.getItem("_ss_i") || "",
    rnduts: sessionStorage.getItem("_rr_n_dts") || "",
    encstr: sessionStorage.getItem("_en_cs") || "",
    "X-Auth-Token":
      sessionStorage.getItem("accessToken") !== null
        ? JSON.parse(sessionStorage.getItem("accessToken")).accessToken
        : "",
  };
};

export const header_without_sessionId = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  rnduts: rnduts,
  encstr: encstr,
};
