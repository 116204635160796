const currentHost = window.location.hostname;
const local = {
  apiUrl: "https://eqplus-admin.dev.mypepsico.com/v2/dopservice",
  apiUrlWeb: "https://eqplus-admin.dev.mypepsico.com/webservices",
  ssoClientId: "0oa1nwzgmlrojvIdW0h8",
  ssoIssuer: "https://secure.ite.pepsico.com/oauth2/default",
};

const dev = {
  apiUrl: "https://eqplus-admin.dev.mypepsico.com/v2/dopservice",
  apiUrlWeb: "https://eqplus-admin.dev.mypepsico.com/webservices",
  ssoClientId: "0oa1nwzgmlrojvIdW0h8",
  ssoIssuer: "https://secure.ite.pepsico.com/oauth2/default",
};

const staging = {
  apiUrl: "https://eqplus-admin.qa.mypepsico.com/v2/dopservice",
  apiUrlWeb: "https://eqplus-admin.qa.mypepsico.com/webservices",
  ssoClientId: "0oa1nwzgmlrojvIdW0h8",
  ssoIssuer: "https://secure.ite.pepsico.com/oauth2/default",
};

const production = {
  apiUrl: `https://${currentHost}/v2/dopservice`,
  apiUrlWeb: `https://${currentHost}/webservices`,
  ssoClientId: "0oa1sydgqfrvdNhQ20h8",
  ssoIssuer: "https://secure.pepsico.com/oauth2/aus1ljxpv3z9G8NGl0h8",
};

let env;
if (currentHost === "localhost") {
  env = staging;
} else if (currentHost === "eqplus.dev.mypepsico.com") {
  env = dev;
} else if (currentHost === "eqplus.qa.mypepsico.com") {
  env = staging;
} else if (currentHost === "mcoe.mypepsico.com") {
  env = production;
}

export default env;
