function setField(key, val) {
  if (key && val) {
    window.sessionStorage.setItem(key, val);
  }
}

function getField(key) {
  var val;
  if (key) {
    val = window.sessionStorage.getItem(key);
  }
  return val;
}

function deleteField(key) {
  if (key) {
    window.sessionStorage.removeItem(key);
  }
}

function clearAll() {
  window.sessionStorage.clear();
}

const localStore = {
  setField,
  getField,
  deleteField,
  clearAll,
};

export default localStore;
