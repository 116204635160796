import * as TYPE from "../../constants";

const initialState = {
  loading: false,
  allNews: [],
  error: "",
  dashboardNews: [],
  helpData: [],
};

export const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPE.GET_NEWS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPE.SET_NEWS_DATA:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case TYPE.GET_NEWS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
