/* eslint-disable import/no-anonymous-default-export */
import OktaAuth from "@okta/okta-auth-js";
import localStore from "../util/localStore";
import Axios from "axios";
import config from "../util/config";

class OktaApiUtil {
  oktaAuth = new OktaAuth(config.oidc);
  userLogin(email, password) {
    // if (
    //   window.location.pathname !== "/create-account" &&
    //   (sessionStorage.getItem("prevUrl") === null ||
    //     sessionStorage.getItem("prevUrl") !== "/cart-preview")
    // ) {
    // sessionStorage.setItem("prevUrl", window.location.pathname);
    // }
    return this.oktaAuth
      .signIn({ username: email, password: password })
      .then((response) => response)
      .catch((error) => {
        if (error.message) {
          if (error.message === "Invalid token.") {
            const res = this.getToken();
            if (res.tokens !== undefined) {
              this.userLogin(email, password);
            }
          } else {
            return error.message;
          }
        } else {
          return { status: "404" };
        }
      });
  }

  getToken(authCode) {
    return this.oktaAuth.token
      .parseFromUrl({
        grantType: "authorization_code",
        code: authCode,
      })
      .then((res) => {
        console.log("gettoken res", res);

        // manage token or tokens
        var tokens = res.tokens;
        this.oktaAuth.tokenManager.add("idToken", tokens.idToken);
        this.oktaAuth.tokenManager.add("accessToken", tokens.accessToken);
        sessionStorage.setItem("idToken", JSON.stringify(tokens.idToken));
        sessionStorage.setItem(
          "accessToken",
          JSON.stringify(tokens.accessToken)
        );

        localStorage.setItem("idToken", JSON.stringify(tokens.idToken));
        localStorage.setItem("accessToken", JSON.stringify(tokens.accessToken));
        return res;
      })
      .catch((err) => {
        console.log("getToken api err", err);
        return err;
      });
  }

  async renewToken() {
    if (sessionStorage.getItem("accessToken")) {
      const accessTokenObject = await JSON.parse(
        sessionStorage.getItem("accessToken")
      ).accessToken;
      var tokenToRenew = {
        accessToken: accessTokenObject,
        scopes: ["openid", "profile", "email"],
      };

      return this.oktaAuth.token
        .renew(tokenToRenew)
        .then(function (freshToken) {
          sessionStorage.setItem("accessToken", JSON.stringify(freshToken));
          this.oktaAuth.tokenManager.add("accessToken", freshToken);
          return freshToken;
        })
        .catch(function (err) {
          // handle OAuthError
          console.log(err);
          if (this.oktaAuth !== null) {
            this.oktaAuth.tokenManager.remove("accessToken");
            this.oktaAuth.tokenManager.remove("idToken");
          }

          sessionStorage.removeItem("token");
          sessionStorage.removeItem("accessToken");
          sessionStorage.removeItem("idToken");
          sessionStorage.removeItem("user");
          return null;
        });
    } else {
      if (this.oktaAuth !== null) {
        this.oktaAuth.tokenManager.remove("accessToken");
        this.oktaAuth.tokenManager.remove("idToken");
      }
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("accessToken");
      sessionStorage.removeItem("idToken");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user");
    }
  }

  async userTokenRevoke(isCreatePassword = false) {
    if (localStorage.getItem("accessToken")) {
      // // In this example, the access token is stored under the key 'myAccessToken'
      const accessToken = await JSON.parse(localStorage.getItem("accessToken"))
        .accessToken;
      const header = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
          "Cache-Control": "no-cache",
        },
      };
      const url = `${config.oidc.revokeTokenUrl}?token=${accessToken}&token_type_hint=access_token&client_id=${config.oidc.clientId}`;
      return Axios.post(url, {}, header)
        .then((res) => {
          if (this.oktaAuth !== null) {
            this.oktaAuth.tokenManager.remove("idToken");
          }
          localStorage.removeItem("accessToken");
          this.userLogout(isCreatePassword);
          //localStorage.removeItem("idToken");
          return res;
        })
        .catch((error) => {
          console.log(error, "Error in catch");
          if (error.message) {
            if (error.message === "Invalid token.") {
              const res = this.getToken();
              if (res.tokens !== undefined) {
                this.userLogout();
              }
            } else {
              return { status: "404", message: error.message };
            }
          } else {
            return { status: "404", message: error };
          }
        });
    }
  }

  async userLogout(isCreatePassword = false) {
    if (localStorage.getItem("idToken")) {
      const idTokenObject = await JSON.parse(localStorage.getItem("idToken"));
      return this.oktaAuth
        .signOut({
          idToken: idTokenObject,
          postLogoutRedirectUri: isCreatePassword
            ? `${window.location.origin}/createPassword`
            : `${window.location.origin}/login`,
        })
        .then((res) => {
          // this.oktaAuth.tokenManager.remove('accessToken');
          if (this.oktaAuth !== null) {
            this.oktaAuth.tokenManager.remove("idToken");
          }
          localStorage.removeItem("idToken");
          localStorage.removeItem("accessToken");
          sessionStorage.removeItem("user");
          localStore.deleteField("code");

          sessionStorage.removeItem("priceListRes");
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("accessToken");
          sessionStorage.removeItem("idToken");
          sessionStorage.removeItem("csrUser");
          sessionStorage.removeItem("ln");
          sessionStorage.removeItem("chainStore");
          sessionStorage.removeItem("billingSameAsShipping");
          sessionStorage.removeItem("formValues");
          sessionStorage.removeItem("billingValues");
          sessionStorage.removeItem("formAddValues");
          sessionStorage.removeItem("formChangeValues");
          sessionStorage.removeItem("billingInvalid");
          sessionStorage.removeItem("shippingInvalid");
          sessionStorage.removeItem("selectedShippingAddress");
          sessionStorage.removeItem("taxZipCode");
          sessionStorage.removeItem("userAccountId");
          sessionStorage.removeItem("regData");
          sessionStorage.removeItem("priceListId");
          sessionStorage.removeItem("prevUrl");
          sessionStorage.removeItem("CheckRefress");
          sessionStorage.removeItem("handoff_params");
          sessionStorage.removeItem("accountId");
          sessionStorage.removeItem("langTranslation");
          sessionStorage.removeItem("agentId");
          sessionStorage.removeItem("preventToCheckOut");
          sessionStorage.removeItem("configlist");
          sessionStorage.removeItem("userOrderCount");
          sessionStorage.removeItem("mainPricelistId");
          sessionStorage.removeItem("accountList");
          sessionStorage.removeItem("checkoutMode");
          sessionStorage.removeItem("cartItems");
          sessionStorage.removeItem("parentId");
          sessionStorage.removeItem("dop_Customer");
          sessionStorage.removeItem("locationId");
          sessionStorage.removeItem("routeId");
          sessionStorage.removeItem("getChildCustomer");
          return res;
        })
        .catch((error) => {
          if (error.message) {
            return { status: "404", message: error.message };
          } else {
            return { status: "404", message: error };
          }
        });
    }
  }

  refreshToken() {
    return this.oktaAuth.token
      .getWithoutPrompt({
        responseType: "code",
        scopes: ["openid", "profile", "email"],
      })
      .then(function (tokenResponse) {
        sessionStorage.setItem(
          "getWithoutPrompt_later",
          JSON.stringify(tokenResponse.tokens)
        );
        sessionStorage.setItem(
          "idToken",
          JSON.stringify(tokenResponse.tokens.idToken)
        );
        sessionStorage.setItem(
          "accessToken",
          JSON.stringify(tokenResponse.tokens.accessToken)
        );
        if (this.oktaAuth !== null) {
          this.oktaAuth.tokenManager.add(
            "idToken",
            tokenResponse.tokens.idToken
          );
          this.oktaAuth.tokenManager.add(
            "accessToken",
            tokenResponse.tokens.accessToken
          );
        }
        return tokenResponse;
      })
      .catch((err) => console.log(err));
  }
}
export default new OktaApiUtil();
