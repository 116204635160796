/*
 * @description: configure redux store
 */

if (process.env.REACT_APP_ENV === 'production') {
  module.exports = require('./configureStore.prod');
} else if(process.env.REACT_APP_ENV === 'staging'){
  module.exports = require('./configureStore.dev');
}else {
  module.exports = require('./configureStore.dev');
}
