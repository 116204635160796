import * as TYPE from "../../constants";

const initialState = {
  loading: false,
  programs: {},
  error: "",
};

export const programsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPE.GET_PROGRAMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPE.GET_PROGRAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        programs: action.payload,
      };
    case TYPE.GET_PROGRAMS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
