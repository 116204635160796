import * as Type from "../../constants";
const INIT_STATE = {
  selectcard: [],
  cart: [],
  orderCart: [],
  viewOrderFilterState: [],
  orderHistory: [],
};

export const orderReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case "ORDER_CART":
      return {
        ...state,
        orderCart: action.payload,
      };
    case Type.VIEW_ORDER_FILTER_STATE:
      return {
        ...state,
        viewOrderFilterState: action.payload,
      };
    case Type.ORDER_HISTORY_LIST:
      return {
        ...state,
        orderHistory: action.payload,
      };
    default:
      return state;
  }
};
