import React from "react";

export default function Loader() {
  const mystyle1 = {
    position: "fixed",
    top: 0,
    left: 0,
    height: "100vh",
    width: "100%",
    zIndex: 9999999,
  };
  return (
    <div className="loader-wrap nprogress" style={mystyle1}>
      <div className="loader text-center">
        <img
          loading="lazy"
          src="https://share.snacks.com/images/mcoe/loader.svg"
          height={120}
          width={120}
          alt="page loader"
        />

        <span>Loading...</span>
      </div>
    </div>
  );
}
