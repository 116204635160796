export function clearCacheData() {
  // Session Storage
  sessionStorage.removeItem("okta-cache-storage");
  localStorage.removeItem("okta-cache-storage");
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("accessToken");
  sessionStorage.removeItem("idToken");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("idToken");
  sessionStorage.removeItem("_ss_i");
  sessionStorage.removeItem("_rr_n_dts");
  sessionStorage.removeItem("_en_cs");
  sessionStorage.removeItem("selectedBillTo");
  sessionStorage.removeItem("selectedShipTo");
  sessionStorage.removeItem("okta-pkce-storage");
  sessionStorage.removeItem("accountId");
}
