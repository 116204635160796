import axios from "axios";

class ApiClient {
  static getWithoutParams(url, headers = null) {
    return new Promise(function (fulfill, reject) {
      axios
        .get(url, { headers })
        .then(function (response) {
          fulfill(response.data);
        })
        .catch(function (error) {
          if (error && error.response) {
            fulfill(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  }

  static getWithParams(url, params = null, headers = null) {
    return new Promise(function (fulfill, reject) {
      axios
        .get(url, { params, headers })
        .then(function (response) {
          fulfill(response.data);
        })
        .catch(function (error) {
          if (error && error.response) {
            fulfill(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  }

  static postWithParams(url, params, headers) {
    return new Promise(function (fulfill, reject) {
      axios
        .post(url, params, { headers })
        .then(function (response) {
          fulfill(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }

  static putWithParams(url, params, headers) {
    // setAuthorizationToken(axios, token);
    return new Promise(function (fulfill, reject) {
      axios
        .put(url, params, { headers })
        .then(function (response) {
          fulfill(response.data);
        })
        .catch(function (error) {
          if (error && error.response) {
            fulfill(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  }

  static putWithoutParams(url, headers) {
    // setAuthorizationToken(axios, token);
    const params = null;
    return new Promise(function (fulfill, reject) {
      axios
        .put(url, params, { headers })
        .then(function (response) {
          fulfill(response.data);
        })
        .catch(function (error) {
          if (error && error.response) {
            fulfill(error.response.data);
          } else {
            reject(error);
          }
        });
    });
  }
}

export default ApiClient;
